/* ==========================================================================
	Navbar e-commerce
	========================================================================== */

.header-banner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	// justify-content: flex-end;
	height: 2rem;
	padding: 0 1rem;
	background-color: white;

	a{
		color: $brand-primary;
	}
}

.header-banner--left {
	height: 2rem;
	line-height: 2rem;
}

.header-banner--right {
	display: flex;
	height: 2rem;
	line-height: 2rem;

	>a,
	>button {
		display: inline-block;
		background-color: transparent;
		padding: 0 .5rem;
		height: 2rem;
		line-height: 2rem;
		border: none;
		border-left: solid 1px $navbar-default-border;
		color: $brand-primary;
	
		>i {
			margin-right: .5rem;
		}
	}

	a{
		color: $brand-primary;
	}
}

.dropdown-wrapper {
	position: absolute;
	z-index: 1000;
	top: 2rem;
	right: 0;
	width: 100%;
	animation-duration: .3s;

	@media(min-width: $screen-md-min) {
		top: 3rem;
		right: 1rem;
		width: 25rem;
		max-width: 100%;
	}
}

/* ==========================================================================
   Accueil
   ========================================================================== */

// .main-accueil {
//   min-height: 50vh;
// }


/* ==========================================================================
   Accueil
   ========================================================================== */

   .main-accueil {
      @extend .text-center;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
    
      @media(min-width: $screen-sm-min) {
        background-color: rgba(black, .6);    
        background: url(../images/charte/para2.jpg) no-repeat center;
        background-size: cover;
      }
    }
    
    .content-main-accueil {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: height ease-in-out .5s;
      
      @media(min-width: $grid-float-breakpoint) {
        flex-direction: row;
      }
      
      .col-logo {
        @extend .animated;
          @extend .flipInY;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        background-color: white;
        margin-top: 0;
        transition: all ease-in-out .5s;
        
        .header-logo {
    
          >img {
            @extend .img-responsive;
            @extend .center-block;
            width: 10rem;
          }
        }
    
        .header-phone {
          display: inline-block;
          padding-top: 2%;
          font-size: $font-size-large;
          font-weight: 700;
        }
    
        @media(min-width: $grid-float-breakpoint) {
          width: 35%;
        }
      }
    
    
      .col-slider {
          display: flex;
          align-items: center;
        width: 100%;
    
        >.carousel {
          width: 100%;
        }
          
          @media(min-width: $grid-float-breakpoint) {
             width: 70%;
          }
       }
    }
    
    .slider-first {
      @extend .carousel-fade;
    
      figure {
        height: 40rem;
        width: 90rem;
        max-width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
    
    .btn-accueil {
      @extend .btn;
      @extend .btn-lg;
      @extend .btn-primary;
      text-transform: uppercase;
      font-weight: 700;
      margin: 3% auto 0;
    }
    
    .footer-accueil {
      @extend .text-center;
      padding: 6%;
      background-color: white;
    
      @media(min-width: $screen-sm-min) {
        background-color: rgba(white, .8);
      }
    
      h1 {
        font-size: 2.5rem;
      }
    }
    
    .col-footer-accueil-left {
      @extend .col-sm-6;
      @extend .text-left;
      
      @media (max-width: $screen-xs-max) {
        text-align: center;
      }
    }
    
    .col-footer-accueil-right {
      @extend .col-sm-6;
      @extend .text-right;
      
      @media (max-width: $screen-xs-max) {
        text-align: center;
        padding-top: 20px;
      }
    }
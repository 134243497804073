//== Box-shadow (� supprimer et remplacer par les classes dans _effects.scss)
$box-shadow: 0 2rem 4rem -2rem rgba(black, .2);

//== Bases (� supp)
//
//##
$height-img:                  300px;

$brand-primary:         #3a395f;
$brand-second:          #3a395f;
$brand-success:         #3a395f;
$brand-info:            #41aae1;
$brand-warning:         #f26522;
$brand-danger:          #cf2d13;

$font-family-sans-serif:  "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif ;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.

$font-size-base:          16px ;
$line-height-base:        1.9 ; // 20/14
$headings-font-family:     "Josefin Sans", "Helvetica Neue", Helvetica, Arial, sans-serif ;